@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body {
    @apply bg-gray-300 dark:bg-gray-900 dark:text-white;
}

@layer components {
    .ip-base {
        @apply mt-2
      mb-2
      p-2
      block
      w-full
      rounded-md
      bg-gray-100
      border-transparent
      focus:border-gray-500  
      focus:ring-0
      dark:bg-gray-700 
      dark:border-gray-600 
      dark:placeholder-gray-400 
      dark:text-white 
      dark:focus:ring-blue-500 
      dark:focus:border-blue-500;
    }

    .ip-label {
        @apply block 
      mb-2 
      text-sm
      font-medium
      text-gray-900
      dark:text-gray-300;
    }

    .ip-checkbox {
        @apply h-4 
      w-4 
      rounded 
      border-gray-300 
      text-green-600 
      focus:ring-green-500;
    }

    .ip-valid {
        @apply border
      border-green-500;
    }

    .ip-invalid {
        @apply border
      border-red-500;
    }

    .ip-error-message {
        @apply mt-2 text-sm text-red-500 mb-2;
    }

    .btn-primary {
        @apply inline-flex 
      justify-center 
      rounded-lg
      text-sm
      font-semibold
      py-3 
      px-4 
      w-auto 
      whitespace-nowrap
      text-ellipsis
      bg-green-800
      text-white 
      hover:bg-green-700;
    }

    .btn-warning {
        @apply inline-flex 
      justify-center 
      rounded-lg
      text-sm
      font-semibold
      py-3 
      px-4 
      w-auto 
      whitespace-nowrap
      text-ellipsis
      bg-red-500
      text-white 
      hover:bg-red-400;
    }

    .btn-secondary {
        @apply inline-flex 
      justify-center 
      rounded-lg
      text-sm
      font-semibold
      py-3 
      px-4 
      w-auto 
      whitespace-nowrap
      text-ellipsis
      bg-white/0
      text-slate-900
      ring-1
      ring-slate-900/10
      hover:bg-white/25
      hover:ring-slate-900/20
      dark:bg-white/50
      dark:text-slate-900
      dark:ring-1
      dark:hover:bg-white/75;
    }

    .link-btn-primary {
        @apply inline-flex 
      justify-center 
      rounded-lg
      text-sm
      font-semibold
      py-3 
      px-4 
      bg-green-800
      text-white 
      hover:bg-green-700;
    }

    .link-btn-secondary {
        @apply inline-flex
      justify-center
      rounded-lg
      text-sm
      font-semibold
      py-3
      px-4 
      bg-white/0
      text-slate-900
      ring-1
      ring-slate-900/10
      hover:bg-white/25
      hover:ring-slate-900/20
      dark:bg-white/50
      dark:text-slate-900
      dark:hover:bg-white/75;
    }

    .link {
        @apply font-medium 
      text-green-600 
      hover:text-green-500;
    }
}
